import { createContext, useCallback, useContext, useState } from "react";
import axios from "../api/axios";
import { APIS } from "../api";
import { toast } from "react-toastify";
import { handleNavigationError } from "../api/error";
import { useNavigate } from "react-router-dom";
import { componentRoutes } from "../constants/componentRoutes";
import { deleteMediaOnS3 } from "../utils/fileUpload";

export const BlogContext = createContext({
    blogs: [],
    blogDetail: {},
    blogLoader: false,
    onLoadBlog: async () => {},
    onAddBlog: async () => {},
    getBlog: async (values) => {},
    onUpdateBlog: async (values) => {},
    onDeleteBlog: async (values) => {},
    // onAddDepartment: async (values) => {},
    // onUpdateDepartment: async (values) => {},
    // onDeleteDepartment:async (values) => {}
});

export const BlogProvider = ({ children }) => {
    const [blogs, setBlogs] = useState(null);
    const [blogDetail, setBlogDetail] = useState(null);
    const [blogLoader, setBlogLoader] =useState(false);

    const navigate = useNavigate();
    const onLoadBlog = useCallback(async () => {
        setBlogLoader(true);
        try {
            const { data } = await axios.post(APIS.BLOG_LIST);
            if (data.code == 1) {
                setBlogs(data?.data);
            }
        } catch (error) {
            handleNavigationError(navigate, error);
        } finally {
            setBlogLoader(false);
        }
    }, [navigate]);

    const onAddBlog = useCallback(async (values) => {
        setBlogLoader(true)
        try {
            const { data } = await axios.post(APIS.ADD_BLOG, values);
            if (data.code == 1) {
                navigate(componentRoutes.blog);
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
            await onLoadBlog();
        } catch (error) {
            handleNavigationError(navigate, error);
        } finally {
            setBlogLoader(false)
        }
    }, [onLoadBlog, navigate]);

    const getBlog = useCallback(async (values) => {
        const payload = {
            blog_id: values
        }
        setBlogLoader(true);
        try {
            const { data } = await axios.post(APIS.GET_BLOG, payload);
            if (data.code == 1) {
                setBlogDetail(data.data);
            } 
        } catch (error) {
            handleNavigationError(navigate, error);
        } finally {
            setBlogLoader(false);
        }
    }, [navigate]);

    const onUpdateBlog = useCallback(async (values) => {
        setBlogLoader(true)
        try {
            const { data } = await axios.post(APIS.EDIT_BLOG, values);
            if (data.code == 1) {
                await onLoadBlog();
                navigate(componentRoutes.blog);
                toast.success(data.message)
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            handleNavigationError(navigate, error);
        } finally {
            setBlogLoader(false)
        }
    }, [onLoadBlog, navigate]);

    const onDeleteBlog = useCallback(async (values, selectedImage) => {
        const payload = {
            blog_id: values
        }
        console.log(selectedImage);
        let newUrl = selectedImage.split('/').slice(0, -1).join('/'); 
        let imageName = (selectedImage).match(/([^/]+)$/)[0]
        setBlogLoader(true)
        try {
            console.log(selectedImage);
            const { data } = await axios.post(APIS.DELETE_BLOG, payload);
            const res =await  deleteMediaOnS3(newUrl, [imageName])
            console.log(res);
            
            if (data.code == 1) {
                toast.success(data.message);

                await onLoadBlog();
            }
            return data
        } catch (error) {
            handleNavigationError(navigate, error);
        } finally {
            setBlogLoader(false)
        }
    }, [onLoadBlog, navigate]);


    const values = {
        blogs,
        blogDetail,
        blogLoader,
        onLoadBlog: useCallback(
            async() => {
                return await onLoadBlog();
            },
            [onLoadBlog]
        ),
        getBlog: useCallback(
            async(values) => {
                return await getBlog(values);
            },
            [getBlog]
        ),
        onUpdateBlog: useCallback(
            async(values) => {
                return await onUpdateBlog(values);
            }, 
            [onUpdateBlog]
        ),
        onDeleteBlog: useCallback(
            async(values, selectedImage) => {
                return await onDeleteBlog(values, selectedImage);
            },
            [onDeleteBlog]
        ),
        onAddBlog: useCallback(
            async(values) => {
                return await onAddBlog(values);
            },
            [onAddBlog]
        ),
        // onAddDepartment: useCallback(
        //     async(values) => {
        //         return await onAddDepartment(values);
        //     },
        //     [onAddDepartment]
        // ),
        // onUpdateDepartment: useCallback(
        //     async(values) => {
        //         return await onUpdateDepartment(values);
        //     }, 
        //     [onUpdateDepartment]
        // ),
        // onDeleteDepartment: useCallback(
        //     async(values) => {
        //         return await onDeleteDepartment(values);
        //     }, 
        //     [onDeleteDepartment]
        // )
    };



    return (
        <BlogContext.Provider value={values}>
            {children}
        </BlogContext.Provider>
    );
};

export const useBlog = () => useContext(BlogContext);
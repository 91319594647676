import { componentRoutes } from "./componentRoutes";
import TestLayout from "../pages/layouts/test_layout";
import Dashboard from "../pages/admin/dashboard";
import Blog from "../pages/admin/blog";
import AddBlog from "../pages/admin/add_blog";
import EditBlog from "../pages/admin/edit_blog";
import ViewBlog from "../pages/admin/view_blog";
import Login from "../pages/auth/login";
import AuthLayout from "../pages/layouts/auth_layout";
import BlankLayout from "../pages/layouts/blank_layout";
import Pages from "../pages";
import ContactUs from "../pages/admin/contact_us";
import Events from "../pages/admin/Events";

const adminRoutes = [
    {
        path: componentRoutes.adminDashboard,
        component: Dashboard,
        layout: TestLayout
    },
    {
        path: componentRoutes.blog,
        component: Blog,
        layout: TestLayout
    },
    {
        path: componentRoutes.addBlog,
        component: AddBlog,
        layout: TestLayout
    },
    {
        path: componentRoutes.editBlog,
        component: EditBlog,
        layout: TestLayout
    },
    {
        path: componentRoutes.viewBlog,
        component: ViewBlog,
        layout: TestLayout
    },
    {
        path: componentRoutes.events,
        component: Events,
        layout: TestLayout
    },
    {
        path: componentRoutes.contactUs,
        component: ContactUs,
        layout: TestLayout
    }
];

const rootRoutes = [
    {
        path: componentRoutes.root,
        component: Pages,
        layout: BlankLayout
    },
    {
        path: componentRoutes.login,
        component: Login,
        layout: AuthLayout
    },
]

export const pageRoutes = [
    ...rootRoutes,
    ...adminRoutes
];
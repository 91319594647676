import React from "react";
import Compose from "./compose";

import {
  BlogProvider,
  AuthProvider,
  ContactProvider,
  EventProvider,
} from "../src/contexts";

export const AppWrapper = ({ children }) => {
    const allProviders = [
      BlogProvider,
      AuthProvider,
      ContactProvider,
      EventProvider,
    ];

    return (
        <React.Fragment>
          <Compose providers={allProviders}>{children}</Compose>  
        </React.Fragment>
      );
};
import { HrmActions } from "../../pages/components/hrm_actions";

export const adminContactUsColumns = ({ onSelectAction }) => {
  return [
    {
      Header: "Sr. No.",
      accessor: "idd", // Assuming you set 'idd' correctly in the data
      Cell: ({ row }) => {
        return row.original.idd; // Return the idd value
      },
    },
    {
        Header: "Name",
        accessor: (row) => {
          return row?.name || "N/A";
        },
      },
    {
        Header: "Email",
        accessor: (row) => {
          return row?.email || "N/A";
        },
    },
    
    {
        Header: "Phone No",
        accessor: (row) => {
            return row?.phone_number || "N/A";
        },
    },
    {
        Header: "Company Name",
        accessor: (row) => {
            return row?.company_name || "N/A";
        },
    },
    {
        Header: "Description",
        accessor: (row) => {
            return row?.description || "N/A";
        },
    },
    {
      Header: "Actions",
      accessor: (row) => {
        return (
          <div className="text-left">
            <HrmActions
              onSelectAction={onSelectAction}
              row={row}
              isDelete={true}
            />
          </div>
        );
      },
    },
  ];
};

import { componentRoutes } from "../constants/componentRoutes"

export const sidebarLinks = [
    // {
    //     label: 'Dashboard',
    //     icon: 'MonitorDot', // Replace with actual icon class
    //     link: componentRoutes.adminDashboard,
    //     children: []
    // },
    {
        label: 'Blogs',
        icon: 'MonitorDot', // Replace with actual icon class
        link: componentRoutes.blog,
        children: []
    },
    {
        label: 'Add Blog',
        icon: 'MonitorDot', // Replace with actual icon class
        link: componentRoutes.addBlog,
        children: []
    },
    {
        label: 'Contact Us',
        icon: 'MonitorDot', // Replace with actual icon class
        link: componentRoutes.contactUs,
        children: []
    },
    {
        label: 'Events',
        icon: 'MonitorDot', // Replace with actual icon class
        link: componentRoutes.events,
        children: []
    },
    {
        label: 'LogOut',
        icon: 'MonitorDot', // Replace with actual icon class
        link: componentRoutes.root,
        children: []
    },
    
];

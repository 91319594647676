import { toast } from "react-toastify";
import { componentRoutes } from "../constants/componentRoutes";

export const handleNavigationError = (navigate, error, redirectRoute = componentRoutes.blog) => {
    // Log the error for debugging
    console.error("Navigation error:", error);

    // Display a toast message for the user
    toast.error("Session expired or unauthorized access.");

    // Navigate to the provided route or login as default
    navigate(redirectRoute);
};

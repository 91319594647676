
const rootRoutes = {
    root: "/",
    login: "/auth/login",
};

const adminRoutes = {
    adminDashboard: "/dashboard",
    blog: "/blog",
    addBlog: "/add-blog",
    editBlog: "/editblog/:blog_id",
    viewBlog: "/viewblog/:id",
    contactUs: "/contact-us",
    events:"/events",
};

export const componentRoutes = {
    ...rootRoutes,
    ...adminRoutes,
};
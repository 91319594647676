import { Eye } from 'lucide-react'
import { HrmActions } from '../../pages/components/hrm_actions'

export const adminEventColumns = ({ onSelectAction }) => {
  return [
    {
      Header: 'Sr. No.',
      accessor: 'idd', // Assuming you set 'idd' correctly in the data
      Cell: ({ row }) => {
        return row.original.idd // Return the idd value
      }
    },
    {
      Header: 'Name',
      accessor: row => {
        return row?.name || 'N/A'
      }
    },
    {
      Header: 'Description',
      accessor: row => {
        return row?.description || 'N/A'
      }
    },
    {
      Header: 'Email',
      accessor: row => {
        return row?.email || 'N/A'
      }
    },
    {
      Header: 'Date',
      accessor: row => {
        return row?.date || 'N/A'
      }
    },
    {
      Header: 'Start Time',
      accessor: row => {
        return row?.start_time || 'N/A'
      }
    },
    {
      Header: 'End Time',
      accessor: row => {
        return row?.end_time || 'N/A'
      }
    },
    {
      Header: 'Actions',
      accessor: row => {
        return (
          <div className='text-left'>
            <HrmActions
              onSelectAction={onSelectAction}
              row={row}
              isDelete={true}
            />
          </div>
        )
      }
    }
  ]
}

import { createContext, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/axios";
import { APIS } from "../api";
import { componentRoutes } from "../constants/componentRoutes";
import { toast } from 'react-toastify';

export const AuthContext = createContext({
    handleLogin: async (values) => {},
    loading: false,
    token: ""
});

export const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    const handleLogin = useCallback(
        async (values) => {
            setLoading(true);
            try {
                const { data } = await axios.post(APIS.LOGIN, values);
                if (data && data.code == 1) {
                    setLoading(false);
                    sessionStorage.setItem("code", data.code);
                    // localStorage.setItem("admin_token", data.data.access_token);
                    // localStorage.setItem("admin_email", data.data.email);
                    // setToken(data.data.access_token)
                    navigate(componentRoutes.blog);

                    return data;
                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                const errorMessage = error?.response?.data?.message || "An error occurred. Please try again.";
                toast.error(errorMessage);
                console.error("Error during login:", error);
            } finally {
                setLoading(false); // Stop loader
            }
        },
        [navigate]
    );


    const values = {
        token,
        loading,
        handleLogin: useCallback(
            async (values) => {
                return await handleLogin(values);
            },
            [handleLogin]
        ),
    };



    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
import React, { useEffect } from "react";
// import HrmLogo from "../../assets/hrmslogo.png";
import HrmLogo from "../../assets/logo_png.svg";
import BackgroundSVG from "../components/background_svg";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const AuthLayout = ({ children }) => {

  useEffect(() => {
    // Set body classes when the component mounts
    document.body.className = "flex items-center justify-center min-h-screen py-16 lg:py-10 bg-slate-50 dark:bg-zink-800 dark:text-zink-100 font-public";

    // Cleanup function to reset body classes on unmount
    return () => {
      document.body.className = "";
    };
  }, []);
  return (
    <div>
        {/* <div className="flex items-center justify-center min-h-screen py-16 lg:py-10 bg-slate-50 dark:bg-zink-800 dark:text-zink-100 font-public"> */}
        <div className="relative">
          <BackgroundSVG />
          <div className="mb-0 w-screen lg:w-[500px] card shadow-lg border-none shadow-slate-100 relative">
            <div className="!px-10 !py-12 card-body">
              <Link to="#">
                <img
                  src={HrmLogo}
                  alt="Dark Logo"
                  className="block h-14 mx-auto dark:hidden"
                />
              </Link>
                {children}
                <ToastContainer />
            </div>
          </div>
          </div>
          {/* </div> */}
    </div>
  );
};

export default AuthLayout;

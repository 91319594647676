import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});

const s3 = new AWS.S3();

export const uploadImageToS3 = async (file) => {

    let extension = file.type?.split("/")[1];
    const fileName = `${new Date().getTime()}.${extension}`;
    
  const params = {
    Bucket: process.env.REACT_APP_AWS_BUCKET,
    Key: `${process.env.REACT_APP_AWS_FOLDER}${fileName}`, // File name you want to save as in S3
    Body: file,
    ContentType: file.type,
  };

  try {
    const data = await s3.upload(params).promise();
    return fileName;
  } catch (err) {
    console.error("Upload Error", err);
    throw err;
  }
};

export const deleteMediaOnS3 = async (keyPath, arrImageName) => {
  // Configure AWS
  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: 'us-east-1:9db8ad1c-1e89-4218-8f32-70d7fb8765b1', 
    }),
  });

  const s3 = new AWS.S3();

  // Helper function to delete an object from S3
  const deleteObjectFromS3 = (bucketName, objectKey) => {
    const params = {
      Bucket: bucketName,
      Key: objectKey,
    };
    console.log(params);
    return s3.deleteObject(params).promise();
  };

  try {
    const deletePromises = arrImageName.map((imageName) => {
      const objectKey = `${keyPath}/${imageName}`;
      console.log("objectKey", objectKey);
      console.log(" keyPath",  keyPath);
      console.log("imageName",imageName);
      
      // return s3.deleteObject(objectKey).promise();
      
      return deleteObjectFromS3(process.env.REACT_APP_AWS_BUCKET, objectKey);
    });

    // Wait for all deletions to finish
    await Promise.all(deletePromises);

    console.log('All images deleted.');
  } catch (error) {
    console.error('Error occurred while deleting objects:', error);
  }
};

// folder : /Hrms/profile_photo/
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useContact } from "../../contexts";
import { ACTIONS } from "../../constants/actions";
import { adminBlogColumns } from "../../constants/data_columns/blogs";
import HrmLoader from "../components/hrm_loader";
import { HRMTable } from "../components/hrm_table";
import NoData from "../../assets/No-Search-Result.png";
import DeleteModal from "../modals/delete_model";
import { useNavigate } from "react-router-dom";
import { componentRoutes } from "../../constants/componentRoutes";
import { adminContactUsColumns } from "../../constants/data_columns/contact_us";

const ContactUs = () => {

  const [contactData, setContactData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [isDeleteFormVisible, setDeleteFormVisible] = useState(false);
  const [blogId, setBlogId] = useState(null);
  const [viewId, setViewId] = useState(null);

    const { onLoadContact, onDeleteContact, contactLoader, contacts } = useContact();
    
    
    const navigate = useNavigate();
    

    useEffect(() => {
      onLoadContact();
    }, [onLoadContact]);

    useEffect(() => {
      if(contacts?.length > 0) {
        setContactData(
          contacts.map((item) => {
            return {
                id: item.id,
                name: item.name,
                email: item.email,
                phone_number: item.phone_number,
                company_name: item.company_name,
                description: item.description
            }
          })
        )
      }
    }, [contacts]);

//     useEffect(() => {
//       if (blogId !== null) {
//           navigate(componentRoutes.editBlog.replace(':blog_id', blogId));
//       }
//   }, [navigate, blogId]);

//   useEffect(() => {
//     if (viewId !== null) {
//         navigate(componentRoutes.viewBlog.replace(':id', viewId));
//     }
// }, [navigate, viewId]);

    const handleDelete = async () => {
      if (!selectedId) return;
    
      try {
        
        const response = await onDeleteContact(selectedId);
      //   if (response.data.success) {
          // toast.success("Department deleted successfully!");
          // Optionally refresh or update the data here
      //   }
      } catch (error) {
      //   toast.error("Failed to delete department. Please try again.");
      //   console.error(error);
      } finally {
        setDeleteFormVisible(false);
        setSelectedId(null);
      }
    };

    const onSelectAction = useCallback(
      async (row, value) => {
           
           if (ACTIONS.EDIT === value) {
              //  setInitialValues(row);
              setBlogId(row.id);
           } else if (ACTIONS.DELETE === value) {
              setSelectedId(row.id);
              setDeleteFormVisible(true)

           } 
           else {
               console.log("Unhandled action:", value);
           }
       },
       []
   );
   
   const columns = useMemo(() => {
       return adminContactUsColumns({ onSelectAction });
   }, [onSelectAction]);

    return (
        // <div>
        //     <p>hello</p>
        // </div>
        <div>
          <div className="col-span-12 md:order-1 xl:col-span-8 2xl:col-span-6">
        <h5 className="mb-2">Contact List</h5>
    </div>
            <div className="card">
            <div className="card-body">
          {contactLoader ? (
                <HrmLoader />
            ) : contactData?.length > 0 ? (
              <>
                    <HRMTable data={contactData} columns={columns} />
                    {isDeleteFormVisible && 
              <DeleteModal
            isVisible={isDeleteFormVisible}
            onClose={() => {
                setDeleteFormVisible(false)
                setSelectedId(null);
            }}
            onConfirm={handleDelete}
            title="Delete Contact"
            description="Are you sure you want to delete this Contact?"
          />
          }
              </>
            ) : (
                <img src={NoData} alt="No Data Available" className="w-200 h-200 mx-auto" />
            ) }
            </div>
            </div>
        </div>
    )
};

export default ContactUs;
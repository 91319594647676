import { createContext, useCallback, useContext, useState } from "react";
import axios from "../api/axios";
import { APIS } from "../api";
import { toast } from "react-toastify";
import { handleNavigationError } from "../api/error";
import { useNavigate } from "react-router-dom";

export const ContactContext = createContext({
    contacts: [],
    contactLoader: false,
    onLoadContact: async () => {},
    onDeleteContact: async (values) => {},
});

export const ContactProvider = ({ children }) => {
    const [contacts, setContacts] = useState(null);
    const [contactLoader, setContactLoader] =useState(false);

    const navigate = useNavigate();
    const onLoadContact = useCallback(async () => {
        setContactLoader(true);
        try {
            const { data } = await axios.post(APIS.CONTACT_US_LIST);
            if (data.code == 1) {
                setContacts(data?.data);
            }
        } catch (error) {
            handleNavigationError(navigate, error);
        } finally {
            setContactLoader(false);
        }
    }, [navigate]);

    const onDeleteContact = useCallback(async (values) => {
        const payload = {
            contact_id: values
        }
        setContactLoader(true)
        try {
            const { data } = await axios.post(APIS.DELETE_CONTACT_US, payload);
            if (data.code == 1) {
                toast.success(data.message);
                await onLoadContact();
            }
        } catch (error) {
            handleNavigationError(navigate, error);
        } finally {
            setContactLoader(false)
        }
    }, [onLoadContact, navigate]);


    const values = {
        contacts,
        contactLoader,
        onLoadContact: useCallback(
            async() => {
                return await onLoadContact();
            },
            [onLoadContact]
        ),
        onDeleteContact: useCallback(
            async(values) => {
                return await onDeleteContact(values);
            },
            [onDeleteContact]
        ),
    };



    return (
        <ContactContext.Provider value={values}>
            {children}
        </ContactContext.Provider>
    );
};

export const useContact = () => useContext(ContactContext);
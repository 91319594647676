import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ACTIONS, DELETE_ACTION } from '../../constants/actions'
import HrmLoader from '../components/hrm_loader'
import { HRMTable } from '../components/hrm_table'
import NoData from '../../assets/No-Search-Result.png'
import DeleteModal from '../modals/delete_model'
import { useNavigate } from 'react-router-dom'
import { useEvent } from '../../contexts/event-context'
import { adminEventColumns } from '../../constants/data_columns/events'

const Events = () => {
  const [eventData, setEventData] = useState([])
  const [selectedId, setSelectedId] = useState(null)
  const [isDeleteFormVisible, setDeleteFormVisible] = useState(false)
  const [eventId, setEventId] = useState(null)
  const { onLoadEvent, events, eventLoader, onDeleteEvent } = useEvent()
  const navigate = useNavigate()
  useEffect(() => {
    onLoadEvent()
  }, [onLoadEvent])

  useEffect(() => {
    if (events?.length > 0) {
      setEventData(
        events.map(item => {
          return {
            id: item.id,
            name: item.name,
            description: item.description,
            email: item.email,
            date: item.date,
            start_time: item.start_time,
            end_time: item.end_time,
          }
        })
      )
    }
  }, [events])

  const handleDelete = async () => {
    if (!selectedId) return
    const response = await onDeleteEvent(selectedId);
    console.log(response);
    
    onLoadEvent()
    try {

    } catch (error) {
    } finally {
      setDeleteFormVisible(false)
      setSelectedId(null)
    }
  }

  const onSelectAction = useCallback(async (row, value) => {
  if (ACTIONS.DELETE === value) {
              setSelectedId(row.id);
              setDeleteFormVisible(true)

           } 
           else {
               console.log("Unhandled action:", value);
           }
  }, [])

  const columns = useMemo(() => {
    return adminEventColumns({ onSelectAction })
  }, [onSelectAction])

  return (
    <div>
      <div className='col-span-12 md:order-1 xl:col-span-8 2xl:col-span-6'>
        <h5 className='mb-2'>Events</h5>
      </div>
      <div className='card'>
        <div className='card-body'>
          {eventLoader ? (
            <HrmLoader />
          ) : eventData?.length > 0 ? (
            <>
              <HRMTable data={eventData} columns={columns} />
              {isDeleteFormVisible && (
                <DeleteModal
                  isVisible={isDeleteFormVisible}
                  onClose={() => {
                    setDeleteFormVisible(false)
                    setSelectedId(null)
                  }}
                  onConfirm={handleDelete}
                  title='Delete Event'
                  description='Are you sure you want to delete this Event?'
                />
              )}
            </>
          ) : (
            <img
              src={NoData}
              alt='No Data Available'
              className='w-200 h-200 mx-auto'
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Events

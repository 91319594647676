import { createContext, useCallback, useContext, useState } from 'react'
import axios from '../api/axios'
import { APIS } from '../api'
import { toast } from 'react-toastify'
import { handleNavigationError } from '../api/error'
import { useNavigate } from 'react-router-dom'
import { componentRoutes } from '../constants/componentRoutes'

export const EventContext = createContext({
  events: [],
  eventLoader: false,
  onLoadEvent: async () => {},
  onDeleteEvent: async values => {}
})

export const EventProvider = ({ children }) => {
  const [events, setEvents] = useState(null)
  const [eventLoader, setEventLoader] = useState(false)

  const navigate = useNavigate()
  const onLoadEvent = useCallback(async () => {
    setEventLoader(true)
    try {
      const response = await axios.post(APIS.EVENT_LIST)
         const { data } = response
      if (data.code == 1) {
        setEvents(data?.data)
      }else{
        setEvents([])
      }
    } catch (error) {
        setEvents([])
      handleNavigationError(navigate, error)
    } finally {
      setEventLoader(false)
    }
  }, [navigate])

  const onDeleteEvent = useCallback(
    async values => {
      const payload = {
        event_id: values
      }
      setEventLoader(true)
      try {
        const response = await axios.post(APIS.DELETE_EVENT, payload)
        const { data } = response
        if (data.code == 1) {
          toast.success(data.message)
          await onLoadEvent()
        }
        return data
      } catch (error) {
        handleNavigationError(navigate, error)
      } finally {
        setEventLoader(false)
      }
    },
    [onLoadEvent, navigate]
  )

  const values = {
    events,
    eventLoader,
    onLoadEvent: useCallback(async () => {
      return await onLoadEvent()
    }, [onLoadEvent]),
    onDeleteEvent: useCallback(
      async values => {
        return await onDeleteEvent(values)
      },
      [onDeleteEvent]
    )
  }
  return (
    <EventContext.Provider value={values}>{children}</EventContext.Provider>
  )
}

export const useEvent = () => useContext(EventContext)

import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { pageRoutes } from "./pageRoutes";
import { PrivateRoute } from "./privateRoutes";
import { componentRoutes } from "./componentRoutes";

export const AppRoutes = () => {
    const navigate = useNavigate();
  useEffect (() => {
    const token = sessionStorage.getItem("code")

    if (!token) {
      navigate(componentRoutes.login);
      // navigate(componentRoutes.root, { replace: true });
    }
    
  }, [navigate]);
    return (
        <Routes>
            {pageRoutes.map((route, index) => (
                <Route
                path={route.path}
                // element={route.component}
                element={<PrivateRoute route={route} />}
                key={index}
                />
              ))}
        </Routes>
    );
  };

  
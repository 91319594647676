import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";

const BlankLayout = ({ children }) => {

  useEffect(() => {
    // Set body classes when the component mounts
    document.body.className = "flex items-center justify-center min-h-screen py-16 lg:py-10 bg-slate-50 dark:bg-zink-800 dark:text-zink-100 font-public";

    // Cleanup function to reset body classes on unmount
    return () => {
      document.body.className = "";
    };
  }, []);
  return (
    <div>
      {children}
      <ToastContainer />
    </div>
  );
};

export default BlankLayout;

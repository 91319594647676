import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from 'react-router-dom';
import { HrmFormInput } from "../components/hrm_input";
// import { authLogInValidation } from "../../validations/authValidations";
import { useAuth } from "../../contexts";
import 'react-toastify/dist/ReactToastify.css';
import HrmLoader from "../components/hrm_loader";
import { toast } from "react-toastify";

const Login = () => {
  const {
    handleSubmit,
    register,
  } = useForm();

  const { handleLogin, loading } = useAuth();
  
  const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state?.logOutSuccess) {
            toast.success(location.state.message);
            
            // Clear the loginSuccess flag by navigating with replace: true
            navigate(location.pathname, { replace: true, state: {} });
        }
    }, [location.state, navigate]);
  const onSubmitLogin = async (values) => {

    await handleLogin(values);
  }

  return (
    <React.Fragment>
              <div className="mt-4 text-center">
                <h4 className="mb-1 text-custom-500 dark:text-custom-500">
                Welcome Back !
                </h4>
                <p className="text-slate-500 dark:text-zink-200">
                Sign in to continue to TTPro.
                </p>
              </div>
              {/* {loading ? (
            <HrmLoader />
        ) : ( */}
          <form onSubmit={handleSubmit(onSubmitLogin)} className="mt-10" id="registerForm">
            <HrmFormInput
                label="Email ID"
                placeholder="Enter email id"
                // error={errors?.userName ? errors.userName.message : ""}
                { ...register("email") }
            />
            <HrmFormInput
                label="Password"
                type="password"
                placeholder="Enter password"
                // error={errors?.password ? errors.password.message : ""}
                { ...register("password") }
            />
            
            <div className="mt-10">
                <button type="submit" className="w-full text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600">
                    Sign In
                </button>
            </div>
        </form>
    </React.Fragment>
  );
};

export default Login;
